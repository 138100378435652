import React, {Component} from 'react';
import Header from "./menu";
import {Button} from "reactstrap";
import {CgMenuGridO} from 'react-icons/cg';
import {OPEN_MENU} from "../redux/action-types";
import {connect} from "react-redux";
import '../assets/styles/social.scss';
/*import {
    AiFillInstagram,
    AiFillLinkedin,
    BsArrow90DegDown,
    BsArrow90DegUp, ImLinkedin,
    IoLogoCss3,
    IoLogoWhatsapp
} from "@react-icons/all-files";*/

import {IoLogoWhatsapp, IoLogoCss3} from "react-icons/io"
import {ImLinkedin} from "react-icons/im"
import {BsArrow90DegUp, BsArrow90DegDown} from "react-icons/bs"
import {AiFillLinkedin, AiFillInstagram} from "react-icons/ai"


const Master = props => {
    const {openMenu, setOpenMenu} = props;
    const menu = prop => {
        setOpenMenu(!openMenu)
    }

    return (
        <div>
            <span className='menu-btn' onClick={menu}><CgMenuGridO/>Menu</span>
            <Header/>
            {
                props.children
            }
            <div id='social'>
                <h3 className='text-white'>Social</h3>
                <a href="https://www.linkedin.com/in/mohammadzadeh-dev">
                    <ImLinkedin color='#d6d6cf' size={30}/>
                </a>
                <a href="https://www.instagram.com/mohammadzade.web">
                    <AiFillInstagram color='#d6d6cf' size={40}/>
                </a>
                <a href="https://wa.me/9192619736">
                    <IoLogoWhatsapp color='#d6d6cf' size={35}/>
                </a>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        openMenu: state.menuReducer.status
    }
}

const mapDispatchToProps = dispatcher => {
    return {
        setOpenMenu: (status) => dispatcher({type: OPEN_MENU, payload: status}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Master);
