import React, {Component} from 'react';

class NotFound extends Component{
    render() {
        return (
            <div>
                NotFound
            </div>
        );
    }
}

export default NotFound;