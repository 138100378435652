import React, {useEffect} from 'react';
import {Col, Container, Row} from "reactstrap";
import '../../assets/styles/about.scss';
// import {CgGym, FaCaravan, FcMusic, IoMdMusicalNote, SiYourtraveldottv} from "react-icons/all";

import {CgGym} from "react-icons/cg";
import {FaCaravan, FaCode} from "react-icons/fa";
import {FcMusic} from "react-icons/fc";
import {IoMdMusicalNote} from "react-icons/io";
import {SiYourtraveldottv} from "react-icons/si";

import {OPEN_MENU} from "../../redux/action-types";
import {connect} from "react-redux";
import {DiCodeigniter} from "react-icons/di";
import {AiFillHeart} from "react-icons/ai";

const About = props => {
   useEffect(()=>{
       const {openMenu, setOpenMenu} = props;
       setOpenMenu(false)
   },[]);

    return (
        <section id='about'>
            <Container>
                <Row className='about-sec justify-content-center align-items-center'>
                    <Col md={6} className='text-center'>
                        <h1>My Favorites <DiCodeigniter/></h1>
                        <p>I <AiFillHeart size={40} color='red'/> Code  <FaCode size={40} color='gray'/> </p>
                        <p> exercise&nbsp;<CgGym size={40} color='gray'/></p>
                        <p>Travel &nbsp;<FaCaravan size={38} color='gray'/></p>
                        <p>listen music <IoMdMusicalNote size={38} color='gray'/></p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}


const mapStateToProps = state => {
    return {
        openMenu: state.menuReducer.status
    }
}

const mapDispatchToProps = dispatcher => {
    return {
        setOpenMenu: (status) => dispatcher({type: OPEN_MENU, payload: status}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
