import React, {useState} from "react";
import {Link} from 'react-router-dom';
import '../assets/styles/menu.scss';
import {Col} from "reactstrap";
import {OPEN_MENU} from "../redux/action-types";
import {connect} from "react-redux";
import {AiOutlineClose} from "react-icons/ai";

const Menu = props => {
    const {openMenu, setOpenMenu} = props;
    const [activeMenu, setActiveMenu] = useState(window.location.pathname)
    const menu = prop => {
        setOpenMenu(!openMenu)
    }
    // console.log(window.location.pathname)

    return (
        <div id='menu' className={openMenu ? 'active-menu' : null}>
            <span onClick={menu} className='text-white close'><AiOutlineClose/></span>
            <ul>
                <li ><Link to='/'
                           className={activeMenu == '/' ? 'active nav-link' : ''}
                           onClick={() => { setActiveMenu('/') }}>Profile</Link></li>

                <li><Link to='/about'
                          className={activeMenu == '/about' ? 'active nav-link' : ''}>About</Link></li>

                <li><Link to='/contact'
                          className={activeMenu == '/contact' ? 'active nav-link' : ''}>Contact</Link></li>

                <li><Link to='/skills'
                          className={activeMenu == '/skills' ? 'active nav-link' : ''}>Skills</Link></li>

                <li><Link to='/resume'
                          className={activeMenu == '/resume' ? 'active nav-link' : ''}>Resume</Link></li>
                {/*<li><Link to='/qwertyuio'>Not Found</Link></li>*/}
            </ul>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        openMenu: state.menuReducer.status
    }
}
const mapDispatchToProps = dispatcher => {
    return {
        setOpenMenu: (status) => dispatcher({type: OPEN_MENU, payload: status}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);