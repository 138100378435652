import {OPEN_MENU} from "../action-types";

const initialState = {
    status: false
};

export const menuReducer = (state = initialState, action) => {
    switch (action.type){
        case OPEN_MENU:{
            return {
                status: action.payload
            }
        }
        default:{
            return state;
        }
    }
}