import React, {Component, useEffect, useState} from 'react';
import TypeWriterEffect from 'react-typewriter-effect';
import {connect} from 'react-redux';
import {Button, Col, Row, Container} from 'reactstrap';
import Converter from 'number-to-words';
import About from './../About';
import '../../assets/styles/profile.scss';
import Avatar from '../../assets/images/me.png';
import {OPEN_MENU} from "../../redux/action-types";




const Profile = props => {

    const [hasVoted, setHasVoted] = useState(false);
    const [error, setError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [results, setResults] = useState(null);




    useEffect(() => {

        const {openMenu, setOpenMenu} = props;
        setOpenMenu(false)
    }, []);
    return (
        <section id='profile'>
            <Container>
                <Row className='profile-avatar justify-content-center align-items-center'>
                    <Col md={6} className='text-center order-md-1'>
                        <img src={Avatar} className='img-fluid avatar' alt="avatar"/>
                        <h1>Mohammad <br/> Mohammadzadeh</h1>

                    </Col>
                    <Col md={6} className='text-center'>
                        <p>
                            Im Web Developer
                            <br/>
                            with several years of experience in developing web-based software and
                            office automation. Proficient in programming languages
                            Like JavaScript, PHP, NodeJS, as well as the powerful React Library. Ability to analyze
                            requirements and create suitable designs for programs.
                            Ability to work in a team and manage projects. Having high accuracy and solving technical
                            problems. Great interest in using new technologies
                            To provide high quality software.
                            I am currently working on artificial intelligence.
                        </p>
                    </Col>

                </Row>
            </Container>
        </section>
    );
}

const mapStateToProps = state => {
    return {
        openMenu: state.menuReducer.status
    }
}

const mapDispatchToProps = dispatcher => {
    return {
        setOpenMenu: (status) => dispatcher({type: OPEN_MENU, payload: status}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

