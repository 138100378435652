import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Container} from 'reactstrap';
import '../../assets/styles/contact.scss';
import {MdEmail} from "react-icons/md";
import {FaMobile} from "react-icons/fa";
import {HiLocationMarker} from "react-icons/hi";
import {ImLocation} from "react-icons/im";
import {OPEN_MENU} from "../../redux/action-types";
import {connect} from "react-redux";

const Contact = props => {
    const [hideElm, setHideElm] = useState(true);

    const hideSection = () => {
        setHideElm(false);
    }

    useEffect(() => {
        setTimeout(() => {
            setHideElm(false);
        }, 2000);
    }, []);

    useEffect(() => {
        const {openMenu, setOpenMenu} = props;
        setOpenMenu(false)
    }, []);


    return (
        <section id='contact'>
            <Container>
                <div className='contact-box justify-content-center align-items-center'>
                    <Col lg={6} className='text-center'>
                        <h1 /*className={hideElm ? '' : 'h1-leave'}*/>Contact Me</h1>
                        <div>
                            <p><MdEmail/> mohammadzadehmzg@gmail.com</p>
                            <p><FaMobile/> 09192619736</p>
                            <p><HiLocationMarker/> West of Tehran</p>
                        </div>
                    </Col>
                </div>
            </Container>
        </section>
    );
}

const mapStateToProps = state => {
    return {
        openMenu: state.menuReducer.status
    }
}

const mapDispatchToProps = dispatcher => {
    return {
        setOpenMenu: (status) => dispatcher({type: OPEN_MENU, payload: status}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);

