import React, {useEffect} from 'react';
import {Col, Container, Row} from "reactstrap";
import Skeleton from "../../components/Skeleton";
import '../../assets/styles/skills.scss';
import Js from '../../assets/images/js.png';
import Html from '../../assets/images/html.png';
import Css from '../../assets/images/css.png';
import bootstrap from '../../assets/images/bootstrap.png';
import jquery from '../../assets/images/jquery.png';
import react from '../../assets/images/react.png';
import php from '../../assets/images/php.png';
// import git from '../../assets/images/git.png';
// import {
//     DiGit,
//     FaBootstrap,
//     ImHtmlFive,
//     IoLogoCss3,
//     SiBootstrap,
//     SiJavascript,
//     SiJquery, SiMongodb,
//     SiNodedotjs,
//     SiReact, SiRedux
// } from "react-icons/all";

import {DiGit} from "react-icons/di";
import {ImHtmlFive} from "react-icons/im";
import {IoLogoCss3} from "react-icons/io";
import {SiJavascript} from "react-icons/si";
import {SiJquery} from "react-icons/si";
import {SiMongodb} from "react-icons/si";
import {SiNodedotjs} from "react-icons/si";
import {SiReact} from "react-icons/si";
import {SiRedux} from "react-icons/si";

import {OPEN_MENU} from "../../redux/action-types";
import {connect} from "react-redux";

const Skills = (props) => {
    useEffect(() => {
        const {openMenu, setOpenMenu} = props;
        setOpenMenu(false)
    }, []);
    return (
        <section id='skill'>
            <Container>
                <div className='skills justify-content-center align-items-center'>
                    <Col lg={6} className='text-center'>

                        <h1 className="">Skills</h1>
                        <Row className='skill-box'>
                            <Col sm={6} md={3} className='skill-item'>
                                {/*<img src={Html} className='img-fluid' alt="html"/>*/}
                                <ImHtmlFive color='#DD4B25' size={50}/>
                                <p> Html 5</p>
                            </Col>

                            <Col sm={6} md={3} className='skill-item'>
                                {/*<img src={Css} className='img-fluid' alt="css"/>*/}
                                <IoLogoCss3 color='#2862E9' size={50}/>
                                <p> Css 3</p>
                            </Col>

                            <Col sm={6} md={3} className='skill-item'>
                                {/*<img src={Js} className='img-fluid' alt="js"/>*/}
                                <SiJavascript color='#FFD600' size={40}/>
                                <p> Javascript</p>
                            </Col>

                            <Col sm={6} md={3} className='skill-item'>
                                <img src={bootstrap} className='img-fluid' alt="bootstrap"/>
                                {/*<FaBootstrap color='#7952B3' size={40}/>*/}
                                <p> Bootstrap Last v</p>
                            </Col>

                            <Col sm={6} md={3} className='skill-item'>
                                {/*<img src={jquery} className='img-fluid' alt="jquery"/>*/}
                                <SiJquery color='#0865A6' size={40}/>
                                <p>Jquery</p>
                            </Col>

                            <Col sm={6} md={3} className='skill-item'>
                                {/*<img src={react} className='img-fluid' alt="react"/>*/}
                                <SiReact color='#5DDAFB' size={50}/>
                                <p>React</p>
                            </Col>

                            <Col sm={6} md={3} className='skill-item'>
                                {/*<img src={react} className='img-fluid' alt="react"/>*/}
                                <SiRedux color='#7649BB' size={50}/>
                                <p>Redux</p>
                            </Col>

                            <Col sm={6} md={3} className='skill-item'>
                                {/*<img src={Js} className='img-fluid' alt="js"/>*/}
                                <SiNodedotjs color='#73aa62' size={40}/>
                                <p> Node Js</p>
                            </Col>

                            <Col sm={6} md={3} className='skill-item'>
                                {/*<img src={Js} className='img-fluid' alt="js"/>*/}
                                <SiNodedotjs color='#73aa62' size={40}/>
                                <p> Next Js</p>
                            </Col>


                            <Col sm={6} md={3} className='skill-item'>
                                <img src={php} className='img-fluid' alt="php"/>

                                <p>Php</p>
                            </Col>

                            <Col sm={6} md={3} className='skill-item'>
                                {/*<img src={git} className='img-fluid' alt="git"/>*/}
                                <SiMongodb color='#429543' size={55}/>
                                <p>MongoDB</p>
                            </Col>

                            <Col sm={6} md={3} className='skill-item'>
                                {/*<img src={git} className='img-fluid' alt="git"/>*/}
                                <DiGit color='#F4511E' size={55}/>
                                <p>Git</p>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Container>
        </section>
    );
}

const mapStateToProps = state => {
    return {
        openMenu: state.menuReducer.status
    }
}

const mapDispatchToProps = dispatcher => {
    return {
        setOpenMenu: (status) => dispatcher({type: OPEN_MENU, payload: status}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Skills);


