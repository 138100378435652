import React, {useEffect} from 'react';
import {Col, Container, Row} from "reactstrap";
import '../../assets/styles/resume.scss';
import {OPEN_MENU} from "../../redux/action-types";
import {connect} from "react-redux";

const Resume = props => {
    useEffect(() => {
        const {openMenu, setOpenMenu} = props;
        setOpenMenu(false)
    }, []);
    return (
        <section id='resume'>
            <Container>
                <div className='resume justify-content-center align-items-center'>
                    <Col lg={6}>

                        <h1 className="">Resume</h1>

                        <div className='resume-item p-3'>
                            <h5>Senior Web Developer</h5>
                            <p> Iran textbooks publishing company · Full-time</p>
                            <p>Design and implementation of office automation and intra-organizational web applications for better management of resources, costs, purchases, personnel, etc. with different and classified accesses.</p>
                        </div>
                        <div className='resume-item p-3'>
                            <h5>Solico Company</h5>
                            <p>Front-End Developer (pure technology ...)</p>
                        </div>
                        <div className='resume-item p-3'>
                            <h5>Ngra Company</h5>
                            <p>Web Developer (React, ReactNative, PWA, Jquery, ...)</p>
                        </div>
                        <div className='resume-item p-3'>
                            <h5>G5 Company</h5>
                            <p>Web Developer (Html, Css, Js, Jquery, PWA, php, ...)</p>
                        </div>
                        <div className='resume-item p-3'>
                            <h5>Nokhbegan-javan Company</h5>
                            <p>Front-End Developer (pure technology ...)</p>
                        </div>
                        <div className='resume-item p-3'>
                            <h5>Aftab Company</h5>
                            <p>Web Developer (My Jump platform)</p>
                        </div>
                        <div className='resume-item p-3'>
                            <div className='resume-item p-3'>
                                <h5>And Many Freelance Projects ...</h5>
                            </div>
                        </div>
                    </Col>
                </div>
            </Container>
        </section>
    );
}


const mapStateToProps = state => {
    return {
        openMenu: state.menuReducer.status
    }
}

const mapDispatchToProps = dispatcher => {
    return {
        setOpenMenu: (status) => dispatcher({type: OPEN_MENU, payload: status}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Resume);
