import Profile from '../pages/Profile';
import About from "../pages/About";
import Contact from "../pages/Contact";
import Skills from "../pages/Skills";
import Resume from "../pages/Resume";
import NotFound from "../pages/NotFound";
import Master from './../containers';


const routes = [
    {
        path: '/',
        exact: true,
        Component: Profile,
        Master
    },
    {
        path: '/about',
        exact: true,
        Component: About,
        Master
    },
    {
        path: '/skills',
        exact: true,
        Component: Skills,
        Master
    },
    {
        path: '/contact',
        exact: true,
        Component: Contact,
        Master
    },
    {
        path: '/resume',
        exact: true,
        Component: Resume,
        Master
    },
    {
        exact: true,
        Component: NotFound
    }
];

export default routes;