import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import routes from './configs/routes';


class App extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    {
                        routes.map(({Component, path, exact, Master}, index) => {
                            return (
                                <Route
                                    key={index}
                                    path={path}
                                    exact={exact}
                                    render={(props) => (
                                        Master ?
                                            <Master {...props}>
                                                <Component {...props}/>
                                            </Master>
                                            :
                                            <Component {...props}/>
                                    )}
                                />
                            )
                        })
                    }
                </Switch>
            </Router>
        )
    }
}

export default App;